.terms-conditions-container {
    max-width: 800px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    white-space: break-spaces;
    text-align: left;
    h1, h2 {
        color: white;
        font-size: x-large;
    }

    p, li {
        color: white;
        font-size: 16px;
        line-height: 1.6;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    a {
        color: #0077cc;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    img.terms-home-logo {
        display: block; 
        max-width: 100px;
        margin: 0 auto 20px; 
        height: auto;
    }

    .btninfo {
        display: block;
        width: 100%; 
        padding: 10px;
        background-color: #0044cc; 
        color: white; 
        text-align: center;
        border: none;
        border-radius: 5px;
        margin-top: 20px; 
        cursor: pointer; 

        &:hover {
            background-color: darken(#0044cc, 10%);
        }
    }
}

