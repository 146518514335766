@import "../variables";
.Modal {
    position: fixed;
    z-index: 500;
    width: 90%;
    padding: 16px;
    position:absolute;
    top: 25%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    transform: translateX(0px);
    opacity: 1;
    overflow: hidden;
    display: block;
    border-radius: 9px;
  }
  .backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left:0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modalbtn
  {
    padding:10px;
    font-weight:bold;
    margin-top:40px;  
    border-radius:5px;
    // background-color: $theme-rustyOrange;
    width:40%;

  }
  .margin
  {
    margin-right: 8px;
  }
  .msgbtn
  {
    background-color: white;
    padding:11px;
    font-weight:bold;
    margin-top:20px; 
    border-radius:30px;
    font-size:1rem;
    color:black;
  }
  .model-close{
    position: absolute;
    right:0;
    top:0;
    padding:.5rem;
    height:40px;
  }
  .modal-bg
  {
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    margin: auto;
    min-height: 100vh;
    width:100%;
    top:0;
    max-width: 480px;
    z-index:2000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.model-content
{
background: $card-bg;
border-radius: 5px;
padding-top: 2rem;
padding-bottom: 2rem;
position: relative;
min-width: 80%;
max-width: 80%;
}
.bg-black
{
  background-color: black;
}
.capitailize
{
  text-transform: capitalize;
}
.modal-img{
  height:80px !important;
}