@import '../variables';
.profile {
    // margin: 30% 5%;
    margin:40% 5%;
    width: 90%;
    .row{
        display: flex;
        color: white;
        justify-content: space-between;
        margin: 20px auto;      
    }
    .center{
        text-align: center;
    }
}
.profile-card
{
    background:linear-gradient(to top,$neon-blue,$neon-green) !important;
    color:white;
    border-radius: 5px;
    padding:.5rem;
    position: relative;
    h3{
        font-size: 1.25rem;
        font-weight: 1000;
    }
    h2
    {
        font-size:0.75rem;
        font-weight: 500;
    }
}
.profile-card::before {
    content: '';
    position: absolute;
    top: 3px; 
    bottom: 3px; 
    left: 3px;
    right: 3px;
    border-radius: 5px; 
    border: .5px solid white; 
    pointer-events: none; 
    box-sizing: border-box; 
  }
/* Media Query for small screens */
@media (max-width: 400px) {
    .profile-card{
      h3 {
        font-size: 1rem; // Smaller font size
      }
    }
}
.image
{
    height: 15px;
    padding: auto !important;
}
.user-status
{
    display: flex;
    flex-direction: row;
}
.unsubBtn
{
    margin-top: 10px;
    padding: 14px;
    font-weight: bold;
    border:2px solid transparent;
    border-radius: 30px;
}
