@import './variables';
.hero {
  &.bg {
      display: block;
      position: relative; 
      background: $theme-darkBlue;
      background-repeat: no-repeat;
      background-image: url("./assets/images/page_bg.jpg");
      background-position: 50%;
      background-size: cover;
      max-width: 480px;
      margin: 0 auto; 
      padding:0px;
  }
}
.ld-bg
{
align-items: center;
background-color: rgba(0, 0, 0, .2);
min-height: 100vh;
position: fixed;
top: 0;
width: 480px;
z-index: 1000;
}
.navbar {
  background: url("./assets/icons/logo_top_bar_bg.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center bottom !important;
  height: 10vh;
  margin-bottom: 1rem;
  background-size: cover !important;
  width: 100%;
  &.is-mobile {
      min-height: auto;
      .navbar-menu {
          display: block;
          background-color: transparent;
          box-shadow: none;
          padding: 0.3rem 0;
          .navbar-item {
              display: inline-flex;
              vertical-align: text-top;
              &:hover {
                  background-color: rgba(250,250,250, 0.4);
              }
          }
      }
  }
}

.container {
  &.m-w {
      width: 95%;
  }
}

.v-center {
  display: flex !important;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.ml-auto {
  margin-left: auto
}
.text-white
{
  color:white !important;
}
.bg
{
  background-color: none;
  border: 1px solid transparent;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
  font-size: .75rem;
  font-weight: 500;
}
.slider-card{
  card
{

  border-radius: 0.25rem;
  color: #4a4a4a;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
}
.flex{
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content: space-between;
  white-space: break-spaces;
}
.word-break
{
  white-space: break-spaces;
}
.flex-left{
  justify-content: flex-start;
  text-align: left;
  width:70%;
  font-size:.75rem;
  overflow: hidden;
  display: -webkit-box;  
  -webkit-box-orient: vertical;  
  -webkit-line-clamp: 1;
  overflow: hidden; 
}
.flex-right{
  justify-content: flex-end;
  text-align: right;
  width:20%;
  align-items: flex-end;
}
.display
{
  display: flex !important;
}
.star-icon{
  height:.75rem;
  margin-top: .2rem;
}
.img-radius{
  border-radius: .5rem;
}
.play-btn
{
  max-width:130px;
  cursor: pointer;
}
@media only screen and (max-width: 350px) {
.play-btn
{
max-width: 60px;
max-height:70px;
}
}
.game-desc
{
 white-space: break-spaces;
 text-align: justify;
 margin-top:.5rem;
 line-height:2 !important;
}
.cat-icon
{
  margin-left: 1rem;
  height: 1rem;
  vertical-align: middle;
  margin-top:-0.5rem;
}
.loader-container {
    position: absolute;
    height: 100vh;
    top:0;
    left:0;
    width:100%;
    max-width: 480px;
    z-index:2000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds
{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 0.6s linear infinite;
}
.lds::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid $theme-lightPurple;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
nav{
  width:100%;
}

.show
{
  display: block;
}
.hide
{
  display: none;
}
.slider-img{
  margin-top: -5%;
  margin-bottom: 2%;
}

.rating-icon
{
height:11px;
width:10px;
}
.p-0
{
padding:0;
}
.m-auto{
margin:auto;
}

.relative
{
position: relative;
}
.mt-8
{
margin-top:3rem !important;
}
.align-left
{
text-align: left;
// width:80%;
}
.mt-6
{
margin-top:2rem !important; 
}
.links
{
display: flex;
flex-direction: row;
justify-content: baseline;
}
.link
{
margin-top:6px;
margin-left:6px;
}
.text-black
{
color:black;
}
.display {
display: flex!important;
flex-direction: row;
justify-content: space-between;
}
.text-lg {
font-size: larger!important;
}
.text-2xl
{
  font-size: 1.5rem;
}
.bold {
font-weight: 900;
}
.text-start {
text-align: left;
}
.semibold
{
font-weight: 400;
}
.btn
{
  background: linear-gradient(150deg, $theme-neonPink 20%, $theme-neonBlue 60%, $theme-neonSky 100% );
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: white;
  padding: 0.75rem;
  position: relative;
  cursor: pointer;
}
.btn:disabled
{
  opacity: 0.6;
  cursor: not-allowed;
}
.btn::before {
  content: '';
  position: absolute;
  top: 3px; 
  bottom: 3px; 
  left: 3px;
  right: 3px;
  border-radius: 5px; 
  border: .5px solid white; 
  pointer-events: none; 
  box-sizing: border-box; 
}

.capitalize
{
text-transform: capitalize;
}
.view-btn{
  background: $theme-lightPurple;
  border-radius: 0.25rem;
}
.language-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.lang-code
{
  margin-left:1rem;
  color:white !important;
}
.language-option {
  padding: 15px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  background:linear-gradient(to top,$neon-blue,$neon-green) !important;
  font-weight: 900;
  color:white;
}
.lazy-img
{
    background-image: url("./assets/icons/placeholder.png");
    background-position: 50%;
    background-size: cover;
}

.lang-icon
{
  height:1.5rem;
}
.remote-icon
{
  height: .85rem;
}
.sub-loader{
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space:break-spaces;
  
  h1
  {
    font-size: 1.5rem;
    margin:1rem 0rem;
  }
  p
  {
    font-size: 1rem;
    word-wrap: break-word; 
    word-break: break-word;
    max-width: 90%;
  }
}
.logo-end
{
  position: absolute;
  right:0;
  margin-right:1rem;
}
#right-img
{
  height: 40px;
  position: absolute;
  right:3px;
}
.serviceLogo
{
   height:3rem;
   margin-top:0.5rem;
   margin-right: .5rem;
}
.cancelBtn
{
  background: url("./assets/icons/cancel_cta_bg.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  background-size: cover;
}