.containerinfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    height: 100vh;
    white-space: break-spaces;
}

.logoinfo {
    height: 100px;
    width: 100px;
    margin-top: 20px; 
    margin-bottom: 20px;
}

.icon-container {
 
    border-radius: 50%; 
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    margin-bottom: 20px; 
}

.heading {
    color: #FFFFFF; 
    margin-bottom: 0.65; 
    font-size: 2rem; 
    text-transform: capitalize;
    font-weight: 900;
}

.para {
    color: #FFFFFF;
    margin-bottom: 1.25rem; 
    padding: 0 1.25rem; 
    font-size: 1rem; 
}

.btninfo {
    background-color: #C30C76; 
    color: #FFFFFF;
    border: none;
    padding: 12px 24px; 
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width:100%;
    text-transform: uppercase;
    font-weight: 900;
    transition: background-color 0.3s;
}

.foot{
    padding-top: 50px;
}


